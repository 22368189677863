import { useState, useEffect, useRef } from 'react';
import NavigationContent from './NavigationContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './main-navigation.module.scss';
import { NavItem } from './MainNavigation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import classNames from 'classnames';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';

type NavigationLinksProps = {
    links: NavItem[];
    active: number;
};

const NavigationLinks = ({ links, active }: NavigationLinksProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [itemActive, setItemActive] = useState(active);
    const clickRef = useRef<HTMLUListElement | null>(null);
    // Dismisses the navigation when clicking outside the main element.
    const handleClickOutside = () => {
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    useOutsideClick(clickRef, handleClickOutside);

    useEffect(() => {
        const closeButton = document.querySelector(
            '[data-header-navigation-close]',
        );

        if (closeButton) {
            closeButton.addEventListener('click', closeNavigation);
        }

        return () => {
            if (closeButton) {
                closeButton.removeEventListener('click', closeNavigation);
            }
        };
    });

    // Click action for click navigation links that have content.
    const toggleNavContent = (contentIndex: number, label: string) => {
        setIsMenuOpen(!isMenuOpen);
        setItemActive(!isMenuOpen ? contentIndex : -1);

        if (!isMenuOpen) {
            trackClick(EventNames.MAIN_NAVIGATION_ITEM_CLICKED, {
                label,
                title: 'Opened main navigation',
            });
        }

        if (document.body.getAttribute('data-side-navigation') === 'true') {
            document.body.removeAttribute('data-side-navigation');
        }
        // Let's tell the main container that the menu is open
        if (!isItemActive(contentIndex)) {
            document.body.setAttribute('data-header-navigation', 'true');
        } else {
            document.body.removeAttribute('data-header-navigation');
        }
        setHeaderHeight();
    };

    const closeNavigation = () => {
        setIsMenuOpen(false);
        setItemActive(-1);
        document.body.removeAttribute('data-header-navigation');
    };

    // Determines if the user has clicked the navigation item and needs to open.
    const isItemActive = (index: number) => {
        return index === itemActive && isMenuOpen;
    };

    // Sets CSS var in the root for global height calculations
    const setHeaderHeight = () => {
        const dataHeaderContainer = document.querySelector(
            '[data-header="navigation"]',
        );
        document.documentElement.style.setProperty(
            '--header-height',
            dataHeaderContainer?.clientHeight + 'px',
        );
    };

    const handleMenuLinkClick = (link: NavItem) => {
        trackClick(EventNames.MAIN_NAVIGATION_ITEM_CLICKED, {
            label: link.label,
            destination_url: link.url,
        });
        window.location.href = link.url as string;
    };

    return (
        <>
            <ul ref={clickRef}>
                {links &&
                    links.map((link: NavItem, i: number) => (
                        <li
                            key={i}
                            data-navigation-open={isItemActive(i)}
                            className={classNames(
                                link.className,
                                styles['list-item'],
                            )}
                        >
                            {link.url ? (
                                <a
                                    href="#"
                                    title={link.label}
                                    tabIndex={0}
                                    onClick={() => handleMenuLinkClick(link)}
                                    target={link.target}
                                >
                                    {link.label}
                                </a>
                            ) : (
                                <button
                                    className={styles['button']}
                                    onClick={() =>
                                        toggleNavContent(i, link.label)
                                    }
                                    data-navigation-trigger={i}
                                    tabIndex={0}
                                >
                                    {link.label}
                                    <FontAwesomeIcon
                                        icon={faAngleDown}
                                        className={'h-4 w-4'}
                                    />
                                </button>
                            )}
                            {link.content ? (
                                <NavigationContent
                                    items={link.content}
                                    key={i}
                                    parentActive={isMenuOpen}
                                    data-content-index={i}
                                    toggleNav={(e) =>
                                        e === true && closeNavigation()
                                    }
                                />
                            ) : null}
                        </li>
                    ))}
            </ul>
        </>
    );
};
export default NavigationLinks;
