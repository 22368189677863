import NavigationItem from './NavigationItem';
import styles from './main-navigation.module.scss';
import { Button } from '@get-mosh/msh-shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavItemContent } from './MainNavigation';
import classNames from 'classnames';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import Routes from 'src/constants/Routes';

type NavigationContentProps = {
    items: NavItemContent[];
    parentActive: boolean;
    'data-content-index': number;
    toggleNav?: (v: boolean) => void;
};

const NavigationContent = ({ ...props }: NavigationContentProps) => {
    return (
        <section className={styles['content']}>
            <div className={styles['items'] + ' md:container'}>
                {props.items.map((item, index) => (
                    <NavigationItem
                        item={item}
                        key={index}
                        parentActive={props.parentActive}
                        closeOnItemClick={(e: boolean) => props.toggleNav?.(e)}
                    />
                ))}
                <TakeTheQuiz
                    parentActive={props.parentActive}
                    className={styles['item--last']}
                />
            </div>
            <button
                className={styles['close']}
                data-header-navigation-close={props['data-content-index']}
                title="Close"
                tabIndex={props.parentActive ? 0 : -1}
            >
                <FontAwesomeIcon icon={faXmark} className={'h-3 w-3'} />
                <span className="hidden">Close</span>
            </button>
        </section>
    );
};

type TakeTheQuizProps = {
    parentActive: boolean;
    className?: string;
};
const TakeTheQuiz = ({ parentActive, className }: TakeTheQuizProps) => {
    return (
        <div className={classNames(className)}>
            <p className="mb-4 mt-2 text-center text-regular">
                Not sure where to start?
            </p>
            <Button
                as="a"
                href={Routes.QUIZZES}
                text="Take free quiz"
                tabIndex={parentActive ? 0 : -1}
                stretch
            />
        </div>
    );
};

export default NavigationContent;
