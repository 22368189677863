import styles from './main-navigation.module.scss';
import NavigationLinks from './NavigationLinks';

type MainNavigationProps = {
    data: NavData;
    itemActive?: number;
};

export type NavData = {
    items: NavItem[];
};

export type NavItem = {
    label: string;
    content?: NavItemContent[] | null;
    url?: string;
    className?: string;
    target?: string;
};

export type NavItemContent = {
    label: string;
    path: string;
    icon: string;
    width?: number;
    height?: number;
};

const MainNavigation = ({ data, itemActive = -1 }: MainNavigationProps) => {
    return (
        <nav className={styles['main-navigation']}>
            <NavigationLinks links={data.items} active={itemActive} />
        </nav>
    );
};

export default MainNavigation;
