import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './side-navigation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import { Button, checkIfSessionIsActive } from '@get-mosh/msh-shared';
import LoginLink from 'src/components/LoginLink/LoginLink';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import Routes from 'src/constants/Routes';
import {
    IconDefinition,
    faBars,
    faSparkles,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import common from 'src/data/common.json';

export type SideNavigationLink = {
    url: string;
    label: string;
    target?: '_blank';
    className?: string;
    component?: string;
    show?: string;
    trackingEvent?: () => void;
    icon?: IconDefinition | string;
};

type SideNavigationProps = {
    links: SideNavigationLink[];
    items: SideNavigationLink[];
};

const SideNavigation = (props: SideNavigationProps) => (
    <aside className={classNames(styles['wrapper'])}>
        <SideNavigationLinks links={props.links} className={styles['links']} />
        <Navigation items={props.items} className={styles['navigation']} />
    </aside>
);

type NavigationProps = {
    items: SideNavigationLink[];
    className?: string;
};

const handleSideNavigationLinkClick = (item: SideNavigationLink) => {
    if (item.label === common.getStartedNow) {
        trackClick(EventNames.MAIN_NAVIGATION_ITEM_CLICKED, {
            label: common.getStarted,
            destination_url: Routes.QUIZZES,
        });
    } else {
        trackClick(EventNames.HAMBURGER_MENU_ITEM_CLICKED, {
            label: item.label,
            destination_url: item.url,
        });
    }
};

const handleTakeFreeQuizClick = () => {
    trackClick(EventNames.HAMBURGER_MENU_ITEM_CLICKED, {
        label: common.takeTheQuiz,
        destination_url: Routes.QUIZZES,
    });
};

const Navigation = (props: NavigationProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navRef = useRef<HTMLElement | null>(null);
    const navContentRef = useRef<HTMLDivElement | null>(null);
    const iconToggle = isOpen ? faXmark : faBars;
    const sessionActive = checkIfSessionIsActive();

    useOutsideClick(navRef, () => {
        if (isOpen) {
            setIsOpen(false);
            clearBodyLocks();
        }
    });

    const toggleNav = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            trackClick(EventNames.MAIN_NAVIGATION_ITEM_CLICKED, {
                label: 'Hamburger',
            });
        }
    };

    useEffect(() => {
        if (isOpen && window.innerWidth < 768) {
            lock(navContentRef.current);
        } else {
            clearBodyLocks();
        }
    }, [isOpen]);

    useEffect(() => {
        document.documentElement.style.setProperty(
            '--vh',
            `${window.innerHeight * 0.01}px`,
        );
        window.addEventListener('resize', () => {
            document.documentElement.style.setProperty(
                '--vh',
                `${window.innerHeight * 0.01}px`,
            );
        });
    }, []);

    return (
        <nav
            ref={navRef}
            className={classNames(props.className)}
            data-nav-open={isOpen}
        >
            <button
                onClick={toggleNav}
                className={classNames(!isOpen && 'z-10')}
                aria-label={isOpen ? 'Open' : 'Close' + ' menu'}
            >
                <FontAwesomeIcon
                    icon={iconToggle}
                    className={classNames(
                        isOpen ? 'h-8 w-8 md:h-9 md:w-9' : 'h-8 w-8',
                    )}
                />
            </button>
            <div className={styles['navigation--container']}>
                <button
                    onClick={() => setIsOpen(false)}
                    className={classNames(
                        'absolute right-4 top-4',
                        'md:hidden',
                        isOpen && 'z-10',
                    )}
                    aria-label={isOpen ? 'Open' : 'Close' + ' menu'}
                >
                    <FontAwesomeIcon icon={faXmark} className={'h-9 w-9'} />
                </button>
                <div className="relative h-full w-full">
                    <div
                        className={styles['navigation--content']}
                        ref={navContentRef}
                    >
                        <SideNavigationLinks
                            links={props.items}
                            className={styles['navigation--links']}
                        />
                        <div className="block md:hidden">
                            <div className="mb-4">
                                <LoginLink button />
                                {!sessionActive ? (
                                    <div className="text-center">
                                        <span className="text-xs text-grey-400">
                                            New to Mosh?{' '}
                                        </span>
                                        <a
                                            className="font-demi text-xs text-green-faint-dark hover:underline"
                                            href={Routes.CREATE_ACCOUNT}
                                        >
                                            Create an account here
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                            <div className="rounded-2xl border border-grey-200 bg-white p-4 shadow">
                                <FontAwesomeIcon
                                    icon={faSparkles}
                                    className="rounded-lg border border-[#76b79b] bg-green-light p-2"
                                />
                                <p className="my-2 font-demi">
                                    Not sure where to start?
                                </p>
                                <p className="p2 mb-4">
                                    Take a quiz to see if you’re eligible
                                </p>
                                <Button
                                    as="a"
                                    type={'link'}
                                    href={Routes.QUIZZES}
                                    text="Take free quiz"
                                    onClick={() => handleTakeFreeQuizClick()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

type SideNavigationLinksProps = {
    links: SideNavigationLink[];
    className?: string;
};

const SideNavigationLinks = (props: SideNavigationLinksProps) => {
    return (
        <ul className={classNames(props.className)}>
            {props.links &&
                props.links.map((e: SideNavigationLink, i: number) => (
                    <li key={i} className={classNames(e.className, e.show)}>
                        {e?.component === 'LoginLink' ? (
                            <LoginLink button />
                        ) : e?.component === 'GetStarted' ? (
                            <Button
                                text={e.label}
                                as="a"
                                href={e.url}
                                variant="primary"
                            />
                        ) : (
                            <a
                                href={e.url}
                                title={e.label}
                                target={e.target}
                                onClick={() => handleSideNavigationLinkClick(e)}
                            >
                                {e.label}
                            </a>
                        )}
                    </li>
                ))}
        </ul>
    );
};

export default SideNavigation;
