'use client';

import EventNames from 'src/analytics/EventNames';
import { trackClick } from 'src/analytics/Events';
import Routes from 'src/constants/Routes';
import { Button, checkIfSessionIsActive } from '@get-mosh/msh-shared';
import { useEffect, useState } from 'react';

type LoginLinkProps = {
  className?: string;
  button?: boolean;
};

const LoginLink = ({ className, button }: LoginLinkProps) => {
  const [linkLabel, setLinkLabel] = useState('Login');
  const [linkPath, setLinkPath] = useState<
    ReturnType<typeof Routes.USER_SIGN_IN> | typeof Routes.NOTIFICATIONS
  >(Routes.USER_SIGN_IN());

  useEffect(() => {
    if (checkIfSessionIsActive()) {
      setLinkLabel('My account');
      setLinkPath(Routes.NOTIFICATIONS);
    }
  }, []);

  const handleLoginClick = () => {
    trackClick(EventNames.MAIN_NAVIGATION_ITEM_CLICKED, {
      label: 'Login',
      destination_url: linkPath,
    });
  };

  return button ? (
    <Button
      className={className}
      href={linkPath}
      onClick={handleLoginClick}
      title={linkLabel}
      variant="secondary"
      as="a"
    >
      {linkLabel}
    </Button>
  ) : (
    <a
      className={className}
      href={linkPath}
      onClick={handleLoginClick}
      title={linkLabel}
    >
      {linkLabel}
    </a>
  );
};

export default LoginLink;
