import styles from './main-navigation.module.scss';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavItemContent } from './MainNavigation';
import { trackClick } from 'src/analytics/Events';
import EventNames from 'src/analytics/EventNames';
import classNames from 'classnames';
import { faArrowRightLong } from '@fortawesome/pro-solid-svg-icons';
import Link from 'next/link';

type NavigationItemProps = {
    item: NavItemContent;
    parentActive: boolean;
    closeOnItemClick?: (v: boolean) => void;
    tile?: boolean;
    className?: string;
};

const NavigationItem = ({
    item,
    parentActive,
    closeOnItemClick,
    tile,
    className,
}: NavigationItemProps) => {
    const handleItemClick = () => {
        trackClick(EventNames.WHAT_WE_TREAT_MENU_ITEM_CLICKED, {
            label: item.label,
            destination_url: item.path,
        });
        closeOnItemClick?.(true);
    };
    return (
        <Link
            className={classNames(
                styles['item'],
                tile && styles['item-tile'],
                className,
            )}
            href={item.path}
            onClick={handleItemClick}
            tabIndex={parentActive ? 0 : -1}
        >
            <Image
                src={item.icon}
                width={item.width}
                height={item.height}
                alt={item.label}
            />
            {tile ? <span>{item.label}</span> : <>{item.label}</>}
            <FontAwesomeIcon icon={faArrowRightLong} className="h-3 w-3" />
        </Link>
    );
};

export default NavigationItem;
